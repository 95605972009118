import React, { useState } from "react";
import { HiMenuAlt4, HiX, HiOutlineDocumentDownload } from "react-icons/hi";
import { FaGithub, FaLinkedin } from "react-icons/fa";
import { motion } from "framer-motion";

import { images } from "../../constants";
import SocialMedia from "../SocialMedia";
import "./Navbar.scss";

const Navbar = () => {
  const [toggle, setToggle] = useState(false);

  return (
    <nav className="app__navbar">
      <div className="app__navbar-logo">
        <img src={images.transparentlogo} alt="logo" />
      </div>
      <ul className="app__navbar-links">
        {["home", "about", "projects", "skills", "contact"].map((item) => (
          <li className="app__flex p-text" key={`link-${item}`}>
            <div />
            <a href={`#${item}`}>{item}</a>
          </li>
        ))}
      </ul>
      <ul className="app__navbar-social">
        <li className="app__flex">
          <div />
          <a
            href="https://github.com/saajan94"
            target="_blank"
            rel="noreferrer"
          >
            <FaGithub />
          </a>
        </li>
        <li className="app__flex">
          <div />
          <a
            href="https://www.linkedin.com/in/saajan-patel-ms/"
            target="_blank"
            rel="noreferrer"
          >
            <FaLinkedin />
          </a>
        </li>
        <li className="app__flex">
          <div />
          <a
            href={images.resume}
            target="_blank"
            rel="noreferrer"
            type="application/pdf"
          >
            <HiOutlineDocumentDownload />
          </a>
        </li>
      </ul>
      <div className="app__navbar-menu">
        <HiMenuAlt4 onClick={() => setToggle(true)} />

        {toggle && (
          <motion.div
            whileInView={{ x: [300, 0] }}
            transition={{ duration: 0.85, ease: "easeOut" }}
          >
            <HiX onClick={() => setToggle(false)} />
            <ul>
              {["home", "about", "projects", "skills", "contact"].map(
                (item) => (
                  <li key={item}>
                    <a href={`#${item}`} onClick={() => setToggle(false)}>
                      {item}
                    </a>
                  </li>
                )
              )}
            </ul>
            <ul className="app__navbar-social">
              <li className="app__flex">
                <div />
                <a
                  href="https://github.com/saajan94"
                  target="_blank"
                  onClick={() => setToggle(false)}
                >
                  <FaGithub />
                </a>
              </li>
              <li className="app__flex">
                <div />
                <a
                  href="https://www.linkedin.com/in/saajan-patel-ms/"
                  target="_blank"
                  onClick={() => setToggle(false)}
                >
                  <FaLinkedin />
                </a>
              </li>
              <li className="app__flex">
                <div />
                <a
                  href={images.resume}
                  target="_blank"
                  type="application/pdf"
                  onClick={() => setToggle(false)}
                >
                  <HiOutlineDocumentDownload />
                </a>
              </li>
            </ul>
          </motion.div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
