import React from "react";
import { HiOutlineDocumentDownload } from "react-icons/hi";
import { FaGithub, FaLinkedin } from "react-icons/fa";
import { images } from "../constants";

const SocialMedia = () => {
  return (
    <div className="app__social">
      <a href="https://github.com/saajan94" target="_blank" rel="noreferrer">
        <div>
          <FaGithub />
        </div>
      </a>
      <a
        href="https://www.linkedin.com/in/saajan-patel-ms/"
        target="_blank"
        rel="noreferrer"
      >
        <div>
          <FaLinkedin />
        </div>
      </a>
      <a
        href={images.resume}
        target="_blank"
        rel="noreferrer"
        type="application/pdf"
      >
        <div>
          <HiOutlineDocumentDownload />
        </div>
      </a>
    </div>
  );
};

export default SocialMedia;
